import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchExpenses = async () =>
  axios.get(`${process.env.REACT_APP_EXPENSES_URL}/expenses`);

const fetchExpense = async (id) =>
  axios.get(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}`);

const createExpense = async (data) =>
  axios.post(`${process.env.REACT_APP_EXPENSES_URL}/expenses`, data);

const updateExpense = async (id, data) =>
  axios.put(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}`, data);

const deleteExpense = async (id) =>
  axios.delete(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}`);

const changeStatePending = async (id, data) =>
  axios.post(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/send`, data);

const validateExpense = async (id) =>
  axios.post(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/validate`);

const cancelExpense = async (id, data) =>
  axios.post(
    `${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/cancel`,
    data
  );

const payExpense = async (id, transaction) =>
  axios.post(`${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/paid`, {
    transaction,
  });

const expensesVatList = async () =>
  axios.get(`${process.env.REACT_APP_EXPENSES_URL}/expenses/data/export`);

const expenseOverview = async (endFiscalYear) =>
  axios.get(
    `${process.env.REACT_APP_EXPENSES_URL}/expenses/data/overview?endFiscalYear=${endFiscalYear}`
  );

const expenseByCategories = async (endFiscalYear) =>
  axios.get(
    `${process.env.REACT_APP_EXPENSES_URL}/expenses/data/overviewByCategories?endFiscalYear=${endFiscalYear}`
  );

const fetchExpensePaginate = async (qs) =>
  axios.get(`${process.env.REACT_APP_EXPENSES_URL}/expenses?${qs}`);
// axios.get(`http://localhost:3001/dev/v1/expenses?${qs}`);

const fetchAllPurchases = async (
  qs = 'pagination=false&filters%5Btype%5D=purchase'
) => axios.get(`${process.env.REACT_APP_EXPENSES_URL}/expenses?${qs}`);

const fetchExpensePdf = async (payload) =>
  axios.post(
    `${process.env.REACT_APP_PDF_PREVIEW}/previewPdf/expense`,
    payload,
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }
  );

export default {
  fetchExpenses,
  fetchExpense,
  createExpense,
  updateExpense,
  deleteExpense,
  changeStatePending,
  validateExpense,
  cancelExpense,
  payExpense,
  expensesVatList,
  expenseOverview,
  expenseByCategories,
  fetchExpensePaginate,
  fetchAllPurchases,
  fetchExpensePdf,
};
