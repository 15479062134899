import React from 'react';
import { get } from 'lodash';

const printErrors = (name, mandatory, errors, touched, errorMessage, style) => {
  const errorToPrint = get(errors, name);
  const touchedKey = get(touched, name);

  if (errorToPrint && touchedKey && mandatory) {
    return (
      <div style={style} className={errorMessage}>
        {errorToPrint}
      </div>
    );
  }

  // if (mandatory && !!(touchedKey && !!errorToPrint)) {
  //   return (
  //     <div style={style} className={errorMessage}>
  //       {errorToPrint}
  //     </div>
  //   );
  // }

  return null;
};

export default printErrors;

const PREFIX = 'ERROR_MESSAGES';

const MESSAGE_ERRORS = {
  DEFAULT: `${PREFIX}.DEFAULT`,
  USER_ALREADY_INVITED_TO_COMPANY: `${PREFIX}.USER_ALREADY_INVITED_TO_COMPANY`,
  USER_LIMIT_REACHED: `${PREFIX}.USER_LIMIT_REACHED`,
};

export const getErrKey = (code) =>
  MESSAGE_ERRORS[code] || MESSAGE_ERRORS.DEFAULT;
