/* eslint-disable import/prefer-default-export */
import { FRANCE_COUNTRY_CODE } from 'config/Constants';
import { EU_COUNTRY_CODES } from 'config/provider';
import { CATEGORIES_PREFIX, SUB_CATEGORIES_PREFIX } from 'constants/expense';
import { DROM_VAT_RATES } from 'constants/vatRates';

export const getSubCategoryId = (provider, categories, t) => {
  if (!categories.data) {
    return provider.id;
  }
  const category = Object.values(categories.data).find((item) => {
    return item._id === provider.category;
  });
  if (category) {
    const subCategory = category.children.find((item) => {
      return (
        t(`${SUB_CATEGORIES_PREFIX}${item.display}`) ===
        t(`${SUB_CATEGORIES_PREFIX}${provider.subCategory}`)
      );
    });
    if (subCategory) {
      return subCategory._id;
    }
  }

  return provider.subCategory;
};

export const getCategoryId = (provider, categories) => {
  if (!categories.data) {
    return provider.id;
  }
  const category = Object.values(categories.data).find((item) => {
    return item.display === provider.category;
  });

  if (category) {
    return category._id;
  }

  return provider.category;
};

export const getCategoryName = (provider, categories, t) => {
  if (!categories.data) {
    return provider.id;
  }
  const category = Object.values(categories.data).find((item) => {
    return item._id === provider.category;
  });

  if (category) {
    return t(`${CATEGORIES_PREFIX}${category.display}`);
  }

  return provider.category;
};

export const translateCategoryName = (name, t) => {
  const translation = t(`${CATEGORIES_PREFIX}${name}`);
  if (translation.includes(CATEGORIES_PREFIX)) return name;
  return translation;
};

export const setProvider = (
  provider,
  setFieldValue,
  values,
  filteredTree,
  t
) => {
  setFieldValue('provider', provider._id);
  setFieldValue('providerSiret', provider.siret);
  setFieldValue('providerName', provider.name);
  setFieldValue('import_subCategory', provider.subCategory);
  setFieldValue('category_id', provider.subCategory);
  setFieldValue('internal_activity', provider.activity);

  const parentCategories = filteredTree.find((category) => {
    const providerTranslation = t(`expenses.categories.${provider.category}`);
    const providerCategory = providerTranslation.includes('expenses.categories')
      ? provider.category
      : providerTranslation;

    const categoryTranslation = t(
      `expenses.categories.${category?.display || category?.category_display}`
    );

    const categoryDisplay = categoryTranslation.includes('expenses.categories')
      ? category.display || category?.category_display
      : categoryTranslation;

    return (
      category._id === providerCategory ||
      category.category_id === providerCategory ||
      categoryDisplay === providerCategory
    );
  });

  setFieldValue(
    'import_category',
    parentCategories?._id || parentCategories?.category_id
  );
  setFieldValue(
    'import_categoryDisplay',
    parentCategories?.display || parentCategories?.category_display
  );
  setFieldValue('import_categoryChildren', parentCategories.children);
};

export const isEuProvider = (provider) => {
  return EU_COUNTRY_CODES.includes(provider?.address?.country);
};

export const isNonEuProvider = (provider) => {
  const providerCountry = provider?.address?.country;
  return (
    providerCountry !== FRANCE_COUNTRY_CODE &&
    !EU_COUNTRY_CODES.includes(provider?.address?.country)
  );
};

export const isCorseProvider = (provider) => {
  return (
    provider &&
    provider?.address?.country === FRANCE_COUNTRY_CODE &&
    provider?.address.zipcode.startsWith('20')
  );
};

/**
 * Checks if the provider is located in a French overseas department or region (DROM).
 *
 * The French overseas departments and regions (DROM) are integral parts of France,
 * although they are located outside mainland France. These regions include:
 * - Guadeloupe (postal code starts with 971)
 * - Martinique (postal code starts with 972)
 * - Mayotte (postal code starts with 976)
 * - Réunion (postal code starts with 974)
 *
 * @param {object} provider - The provider information to check, including address details.
 * @returns {boolean} - Returns true if the provider is located in a DROM region, otherwise false.
 */
const isDromProvider = (provider) => {
  return (
    provider &&
    provider?.address?.country === FRANCE_COUNTRY_CODE &&
    ['971', '972', '976', '974'].some((prefix) =>
      provider?.address?.zipcode.startsWith(prefix)
    )
  );
};

const getDromVatRates = (vatRates, subCategory) => {
  let listVatRate = [...vatRates];
  const listVatRateValue = listVatRate.map((vatRate) => vatRate.value);

  if (listVatRateValue.includes(20)) {
    listVatRate.push(DROM_VAT_RATES[8.5]);
  }
  if (listVatRateValue.some((value) => [5.5, 10].includes(value))) {
    listVatRate.push(DROM_VAT_RATES[2.1]);
  }
  if (subCategory?.vat?.dromVatRates) {
    listVatRate = [...listVatRate, ...subCategory.vat.dromVatRates];
  }

  return listVatRate;
};

export default {
  isDromProvider,
  getDromVatRates,
};
