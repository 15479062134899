import { FRANCE_COUNTRY_CODE } from 'config/Constants';
import logger from 'helpers/logger';

const valuesToData = (values) => {
  const { cga, tva, companyType, micro, prestation, vente, bookkeeping } =
    values;

  const data = {
    _id: values._id,
    name: values.name,
    business_name: values.business_name,
    address: values.address,
    zipcode: values.zipcode,
    city: values.city,
    country: values.country,
    rcs: `${values.rcsType} ${values.rcs}`,
    siren: values.siren,
    nic: values.nic,
    legal_form: values.legal_form,
    legal_form_id: values.legal_form_id,
    capital: values.capital,
    code_ape: values.code_ape,
    intracomvat: values.intracomvat,
    filename: values.filename,
    fax_number: values.fax_number,
    phone_number: values.phone_number,
    website: values.website,
    paypal: { client_id: values.client_id },
    settings: {
      invoices: {
        quotation: values.quotation,
        delivery_form: values.delivery_form,
        order_form: values.order_form,
      },
      tax_registration: {
        cga,
        tva,
        periodic_VAT_returns: tva ? values.periodic_VAT_returns : undefined,
        companyType,
        micro,
        prestation,
        vente,
        return_on_debit: tva ? values.return_on_debit : false,
        bookkeeping,
      },
      payments_methods: {
        check: values.check || false,
        hiPay: values.hiPay || false,
        bridgePaymentLink:
          !values.check &&
          !values.hiPay &&
          !values.cash &&
          !values.paypal &&
          !values.bridgePaymentLink
            ? true
            : values.bridgePaymentLink,
        cash: values.cash || false,
        paypal: values.paypal || false,
        bankTransfer: values.bankTransfer || false,
        lcr: values.lcr || false,
      },
    },
    bank_details: values.bank_details,
    endFiscalYear: values.endFiscalYear,
    isCommercial: values?.isCommercial ?? undefined,
    periodicityReport: values?.periodicityReport || undefined,
  };

  logger.debug('[CompanyHelpers.valuesToData()] returns', { data });
  return data;
};

const mergeFilename = (values, filename) => {
  const data = valuesToData(values);
  return { ...data, filename };
};

export const isCorseCompany = (company) => {
  return (
    company &&
    company?.country === FRANCE_COUNTRY_CODE &&
    company?.zipcode.startsWith('20')
  );
};

export const isGuyaneCompany = (company) => {
  return (
    company &&
    company?.country === FRANCE_COUNTRY_CODE &&
    company?.zipcode.startsWith('973')
  );
};

/**
 * Checks if the company is located in an overseas French territory.
 *
 * The overseas French territories include:
 * - Guadeloupe (postal code starts with 971)
 * - Martinique (postal code starts with 972)
 * - Mayotte (postal code starts with 976)
 * - Réunion (postal code starts with 974)
 *
 * @param {object} company - The company information to check.
 * @returns {boolean} - Returns true if the company is located in an overseas French territory, otherwise false.
 */
const isOverseasFrenchTerritory = (company) => {
  return (
    company &&
    company?.country === FRANCE_COUNTRY_CODE &&
    ['971', '972', '976', '974'].some((prefix) =>
      company?.zipcode.startsWith(prefix)
    )
  );
};

export default { valuesToData, mergeFilename, isOverseasFrenchTerritory };
