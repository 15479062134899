/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useDropzone } from 'react-dropzone';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Layout from 'components/Layout';
import Header from 'components/Header';
import {
  extractPDF,
  uploadPDF,
  getOriginalById,
  getPDFFile,
  getPDFHistory,
} from 'actions/PDFActions';
// import Header from './Header';

import { useRouter } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: 'white',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const PDFContainer = ({ ...props }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { search } = useLocation();
  const originalId = new URLSearchParams(search).get('id');
  const depositId = new URLSearchParams(search).get('depositId');
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const [pdf, setPDF] = useState(null);
  const [pdfImages, setPdfImages] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [original, setOriginal] = useState(null);
  const [selectedPages, setSelectedPages] = useState([]);
  const [extract, setExtract] = useState(null);
  const [extractedImages, setExtractedImages] = useState([]);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        setPDF(file);
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  async function getFileFromUrl(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type,
    });
  }

  const convertPDFToImage = useCallback(async ({ pdfDoc, scale }) => {
    const canvas = document.createElement('canvas');
    const images = [];

    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale });
      // const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { alpha: false });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;
      const image = canvas.toDataURL('image/png');
      images.push(image);
    }
    canvas.remove();
    return images;
    // setPdfImages(images);
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    const [pdfArrayBuffer] = await Promise.all(
      acceptedFiles.map((file) => readFileAsync(file))
    );
    const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

    const pdfDoc = await loadingTask.promise;

    // setPDF(pdfDoc);
    const images = await convertPDFToImage({ pdfDoc, scale: 1 });
    setPdfImages(images);
  }, []);

  const onFileSelected = async (e) => {
    const fileList = e.target.files;
    if (fileList?.length > 0) {
      const pdfArrayBuffer = await readFileAsync(fileList[0]);
      const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

      const pdfDoc = await loadingTask.promise;

      // setPDF(pdfDoc);
      const images = await convertPDFToImage({ pdfDoc, scale: 1 });
      setPdfImages(images);
    }
  };

  const onSelectPage = (indice) => {
    const indiceIdx = selectedIndices.indexOf(indice);
    if (indiceIdx !== -1) {
      setSelectedIndices(selectedIndices.filter((i) => i !== indice));
    } else {
      setSelectedIndices([...selectedIndices, indice]);
    }
  };

  const onExtract = async () => {
    setShowSpinner(true);
    let originalS3 = original;
    if (!originalS3) {
      originalS3 = await dispatch(uploadPDF(pdf));
    }
    const values = {
      file: originalS3,
      indices: selectedIndices,
    };
    const { originalOId, splitFileKey, splitId } = await dispatch(
      extractPDF(values)
    );
    const url = `/pdf/item?originalId=${
      originalId || originalOId
    }&extractedKey=${splitFileKey}&depositId=${
      depositId || ''
    }&splitId=${splitId}`;

    history.push(url);
    setShowSpinner(false);
  };

  useEffect(() => {
    (async () => {
      if (originalId) {
        setShowSpinner(true);
        const { originalFileKey } = await dispatch(getOriginalById(originalId));
        if (originalFileKey) {
          setOriginal(originalFileKey);

          const originalFile = await dispatch(getPDFFile(originalFileKey));

          const file = await getFileFromUrl(originalFile, originalFileKey);

          const pdfArrayBuffer = await readFileAsync(file);
          const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

          const pdfDoc = await loadingTask.promise;

          const images = await convertPDFToImage({ pdfDoc, scale: 1 });
          setPdfImages(images);

          const splitedHistory = await dispatch(
            getPDFHistory(originalId, { pagination: false })
          );
          const splited = splitedHistory.data
            .map((item) => item.splitParams)
            .flat();

          setSelectedPages(splited);
        }
        setShowSpinner(false);
      }
    })();
  }, []);

  const {
    getRootProps,
    getInputProps,
    // fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const renderBody = () => {
    return (
      <div>
        {showSpinner ? (
          <CircularProgressCentered />
        ) : (
          <div className={styles.split}>
            {extractedImages.length ? (
              extractedImages.map((image, index) => {
                const indice = ++index;
                return (
                  <div className={styles['extract-item']} key={index}>
                    <img
                      key={index}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={image}
                      alt="preview"
                    ></img>
                    <div className={styles.indice}>{indice}</div>
                  </div>
                );
              })
            ) : pdfImages.length ? (
              pdfImages.map((image, index) => {
                const indice = ++index;
                const disable = selectedPages.find((item) => item === indice);
                return (
                  <div
                    style={{ cursor: disable ? 'not-allowed' : 'pointer' }}
                    // style={{ cursor: 'pointer' }}
                    className={
                      selectedIndices.find(
                        (selected) => selected === indice
                      ) === indice
                        ? styles['split-item-active']
                        : styles['split-item']
                    }
                    key={index}
                    onClick={() => {
                      if (!disable) {
                        onSelectPage(index);
                      }
                      // onSelectPage(index);
                    }}
                  >
                    <img
                      key={index}
                      style={{
                        width: '100%',
                        height: '100%',
                        ...(disable
                          ? {
                              objectFit: 'cover',
                              opacity: '0.4',
                            }
                          : {}),
                      }}
                      src={image}
                      alt="preview"
                    ></img>
                    <div className={styles.indice}>{indice}</div>
                  </div>
                );
              })
            ) : (
              <div className={styles.dropzone}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} accept="application/pdf" />
                  <CloudUpload style={{ fontSize: 80 }} />
                  <h2>{t('pdf_purchase.drag_and_drop_to_upload_file')}</h2>
                  <div style={{ marginBottom: 12, fontSize: 15 }}>
                    {t('pdf_purchase.or')}
                  </div>
                  <Button
                    className={styles['upload-button']}
                    style={{ background: 'white' }}
                    variant="contained"
                    component="span"
                  >
                    <span style={{ color: '#5257ad' }}>
                      {t('pdf_purchase.browse_file')}
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const goBack = () => {
    history.push('/pdf');
  };

  const renderHeader = () => (
    <Header name={t('pdf_purchase.extract')} goBack={goBack} flexEnd>
      {extractedImages.length ? (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '1rem 1.5rem 1rem 0' }}
          onClick={() => {
            history.push(`/purchases/new?extractKey=${extract}`);
          }}
        >
          {t('pdf_purchase.create_an_purchase')}
        </Button>
      ) : (
        <></>
      )}
      {!extractedImages.length && selectedIndices.length ? (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '1rem 1.5rem 1rem 0' }}
          onClick={onExtract}
        >
          {t('pdf_purchase.extract')}
        </Button>
      ) : (
        <></>
      )}
      {/* {!extractedImages.length ? (
        <>
          <input
            accept=".pdf"
            style={{ display: 'none' }}
            onChange={onFileSelected}
            id="contained-button-file"
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button
              className={styles['upload-button']}
              variant="contained"
              color="primary"
              component="span"
            >
              {t('pdf_purchase.upload_a_new_file')}
            </Button>
          </label>
        </>
      ) : (
        <></>
      )} */}
    </Header>
  );

  return (
    <Layout
      header={renderHeader()}
      sidebarLeft={true}
      sidebarRight={false}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

PDFContainer.propTypes = {
  props: PropTypes.object,
  match: PropTypes.object,
};

export default PDFContainer;
