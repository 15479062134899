import { v4 as uuidv4 } from 'uuid';

/* Import helpers */
import { getFileExtension } from 'helpers/utils';
import logger from 'helpers/logger';
import { authInstance as http } from 'helpers/axiosInterceptor';

/* Import services */
import mediaService from 'services/S3FileService';
import PDFService from 'services/PDFService';

const onSubmit = async (
  { action, ...values },
  { setError, setFieldValue, setSubmitting, props }
) => {
  let createResponseId = null;

  // TODO: only insert here
  // insert or update
  const upsertPurchase = async (id, val) => {
    if (id) {
      const { data } = await http.put(
        `${process.env.REACT_APP_EXPENSES_URL}/purchases/${id}`,
        val
      );
      props.updateInvoiceExpense(id, data);
    } else {
      const { data } = await http.post(
        `${process.env.REACT_APP_EXPENSES_URL}/purchases`,
        val
      );

      createResponseId = data._id;

      const { id: expenseOId, __t } = data;

      // If create expense by using splitted PDF file, update SplitPDFHistory collection
      const splitId = new URLSearchParams(window.location.search).get(
        'splitId'
      );

      if (splitId) {
        await PDFService.updateAfterCreateExpense({
          splitPDFHistoryId: splitId,
          expenseOId,
          expenseType: __t,
        });
      }

      // If create expense by using deposit PDF file, mark deposit file is used file
      const depositId = new URLSearchParams(window.location.search).get(
        'depositId'
      );

      if (depositId) {
        await PDFService.markAsUsedDepositPoolItemFile({
          depositPDFPoolId: depositId,
          isUsed: true,
          expenseId: expenseOId,
        });
      }

      props.createInvoiceExpense(data);
    }
  };

  // mark expense as paid
  const payPurchase = async (id, val) => {
    await upsertPurchase(id, val);
    const { data } = await http.post(
      `${process.env.REACT_APP_EXPENSES_URL}/expenses/${id}/paid`
    );
    props.updateInvoiceExpense(id, data);
  };

  try {
    const {
      loggedUserCompany: { company },
    } = props;
    const { id: expenseId } = props.match.params;

    setSubmitting(true);
    logger.log('Formik values', values);

    let filename = null;
    if (values.filename && values.filename instanceof File) {
      try {
        const file = values.filename;
        const ext = getFileExtension(values.filename.name);
        const folder = `company-files-${company.alternativeId}`;
        const fileName = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

        filename = `${folder}/${fileName}`;

        await mediaService.upload(file, folder, fileName);
      } catch (e) {
        setFieldValue('uploadError', true);
        setFieldValue('action', null);
        setFieldValue('state', 'draft');
        throw new Error({ message: 'error upload file' });
      }
    } else {
      filename = values.filename;
    }

    const data = {
      date: values.date,
      // company_id, user_id, => these values are added on the API
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      client_id: values.client_id,
      project_id: values.project_id,
      invoice_nb: values.invoice_nb,
      internal_activity: values.internal_activity,
      provider: values.provider || null,
      state: 'draft',
      due_date: values.due_date,
      filename,
      payment_type: values.payment_type,
      currency: 'EUR',
      description: values.description,
      buyerOrderReferencedDocument: values.buyerOrderReferencedDocument,
      reference: values.reference,
      is_billable: values.is_billable,
      is_intern: values.is_intern,
      travel: {
        from: values.travel_from,
        to: values.travel_to,
      },
      // belongsToGroup: values.belongsToGroup, default value will be returned
      amounts: values.amounts,
      taxBasisTotalAmount: values.taxBasisTotalAmount,
      taxTotalAmount: values.taxTotalAmount,
      grandTotalAmount: values.grandTotalAmount,
      duePayableAmount: values.duePayableAmount,
      billNumber: values.billNumber,
      orderNumber: values.orderNumber,
    };
    logger.log('API data', data);

    if (action === 'upsert') {
      await upsertPurchase(expenseId, data);
    } else if (action === 'pay') {
      await payPurchase(expenseId, data);
    }

    const goBackPath = props.location?.state?.goBackPath;
    const goBackQueryParams = props.location?.state?.goBackQueryParams;
    const pageTitle = props.location?.state?.pageTitle;

    props.history.push({
      pathname: `/purchases/edit/${createResponseId}` || '/purchases/list',
      state: {
        notShowReconclilier: Boolean(createResponseId),
        goBackPath,
        goBackQueryParams,
        pageTitle,
      },
    });
  } catch (e) {
    setError(e.message);
    setSubmitting(false);
    setFieldValue('action', null);
    setFieldValue('state', 'draft');
  } finally {
    setFieldValue('action', null);
    setSubmitting(false);
  }
};

export default onSubmit;
