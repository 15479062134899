import i18next from 'i18next';
import _ from 'lodash';

import { SUB_CATEGORIES_PREFIX } from 'constants/expense';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import { CORSE_VAT_RATES } from 'constants/vatRates';
import { store } from '../store';
import providerHelpers, {
  isCorseProvider,
  isNonEuProvider,
} from './providerHelpers';
import { sortUniqueVatRates } from './utils';

export const getCorseVatRates = (vatRates) => {
  return [...vatRates, ...CORSE_VAT_RATES];
};

export const getCorseVatRateByCategory = (vatRates, category) => {
  return [...vatRates, ...category?.vat?.corseVatRates];
};

export const getCategoryById = (id) => {
  const { data } = store.getState().categories;

  if (!_.isEmpty(data)) {
    return data[id];
  }
  return undefined;
};

export const getHelpText = (id) => {
  const category = getCategoryById(id);
  if (category && category.helptext && category.helptext.length !== 0) {
    return category.helptext;
  }
  return i18next.t('expenses.expense.helpers.default');
};

export function getTranslateSubCategory(elem, t) {
  const cateTranslate = t(`${SUB_CATEGORIES_PREFIX}.${elem.display}`);

  if (cateTranslate.includes(SUB_CATEGORIES_PREFIX)) {
    return elem.display;
  }
  return cateTranslate;
}

export const getSubCategories = (categoryId, categories) => {
  if (!categoryId || !categories.length) return [];
  return (
    categories.find((item) => item.category_id === categoryId)?.children || []
  );
};

export const getVatRates = (
  categoryId,
  subCategoryId,
  categories,
  fullVatRates,
  provider = null
) => {
  if (provider && isNonEuProvider(provider)) {
    return [fullVatRates[0]];
  }
  const subCategory = getSubCategories(categoryId, categories).find(
    (item) => item._id === subCategoryId
  );
  if (!subCategory) return fullVatRates;
  let listVatRate = subCategory?.vat?.vatRates?.map((item) => {
    return fullVatRates.find((vat) => vat._id === item.id);
  });

  if (isCorseProvider(provider) && subCategory?.vat?.corseVatRates) {
    listVatRate = getCorseVatRateByCategory(listVatRate, subCategory);
  }

  if (providerHelpers.isDromProvider(provider)) {
    listVatRate = providerHelpers.getDromVatRates(listVatRate, subCategory);
  }

  return sortUniqueVatRates(listVatRate);
};

export const filteredTree = (tree, t) => {
  // Finding the target category by its _id
  const targetCategory = tree.find(
    (category) => category._id === DEFAULT_CATEGORY_ID
  );
  // Filtering the children of targetCategory based on travel.isTravelWithDistanceRate property
  const filteredChildren = targetCategory?.children?.filter(
    (category) =>
      !category.travel.isTravelWithDistanceRate && category.hasProvider
  );
  // Filtering the tree based on order property
  const filteredTreeData = tree.filter((category) => category.order > 0);

  // Merging the filteredChildren with filteredTree and mapping to include only display and children properties
  return [...(filteredChildren || []), ...filteredTreeData].map((category) => ({
    category_display: t(`expenses.categories.${category.display}`),
    category_id: category._id,
    children: category.children.filter((item) => item.hasProvider),
  }));
};
