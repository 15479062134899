import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import PricingColumn from 'components/PricingColumn';
import { PricingTabList } from 'containers/Onboarding/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/SubscriptionService';
import spinnerService from 'services/SpinnerService';

import { MONTHLY, YEARLY } from 'constants/subscriptions';
import FormuleBasique from '../../assets/images/formule-asique.png';
import FormulePremium from '../../assets/images/formule-premium.png';

import useStyles from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getSubscriptionsByInterval = (subscriptions, interval) => {
  return subscriptions.filter(
    (subscription) => subscription.interval === interval
  );
};

const AppSubscriptions = ({ handleChoosePricing }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetchSubscriptions = async () => {
    spinnerService.startSpinner();
    try {
      const data = await subscriptionService.getSubscriptions();
      if (data) {
        setSubscriptions(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const getSubscriptionByNameAndInterval = (subscriptionName, interval) => {
  //   if (!subscriptions.length) return null;
  //   return subscriptions.find(
  //     (item) => item.name === subscriptionName && item.interval === interval
  //   );
  // };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const monthlySubscriptions = useMemo(
    () => getSubscriptionsByInterval(subscriptions, MONTHLY),
    [subscriptions]
  );

  const annualSubscriptions = useMemo(
    () => getSubscriptionsByInterval(subscriptions, YEARLY),
    [subscriptions]
  );

  const formatAmount = (amount) =>
    (amount / 100).toFixed(2).toString().replace('.', ',');

  const getSubscriptionInfo = (name) => {
    return name.includes('Starter')
      ? {
          description: 'discovery_description',
          icon: FormuleBasique,
          dataName: 'basique',
        }
      : {
          description: 'efficiency_descriptions',
          icon: FormulePremium,
          dataName: 'premium',
        };
  };

  return (
    subscriptions && (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static" color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            {PricingTabList.map((item, index) => {
              return (
                <Tab
                  key={item}
                  label={t(`onboarding.${item}`)}
                  className={
                    index === value ? classes.buttonActive : classes.button
                  }
                />
              );
            })}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Typography variant="h6" align="center">
            {t('onboarding.pricing_title')}
          </Typography>
          <div className={classes.pricingContainer}>
            {monthlySubscriptions?.map((subscription) => (
              <PricingColumn
                key={subscription._id}
                handleChoosePricing={handleChoosePricing}
                title={subscription.name}
                descriptions={
                  getSubscriptionInfo(subscription.name).description
                }
                price={formatAmount(subscription.amount)}
                dataName={getSubscriptionInfo(subscription.name).dataName}
                icon={getSubscriptionInfo(subscription.name).icon}
                isRounded={false}
                buttonText={'start_trial'}
                monthly
                dataSubscription={subscription}
                hasAI={subscription?.hasAI || false}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" align="center">
            {t('onboarding.pricing_title')}
          </Typography>
          <div className={classes.pricingContainer}>
            {annualSubscriptions?.map((subscription) => (
              <PricingColumn
                key={subscription._id}
                handleChoosePricing={handleChoosePricing}
                title={subscription.name}
                descriptions={
                  getSubscriptionInfo(subscription.name).description
                }
                price={formatAmount(subscription.amount)}
                dataName={getSubscriptionInfo(subscription.name).dataName}
                icon={getSubscriptionInfo(subscription.name).icon}
                isRounded={false}
                buttonText={'start_trial'}
                monthly
                dataSubscription={subscription}
                hasAI={subscription?.hasAI || false}
              />
            ))}
          </div>
        </TabPanel>
      </div>
    )
  );
};

AppSubscriptions.propTypes = {
  handleChoosePricing: PropTypes.func,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default AppSubscriptions;
