import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { EXPENSE_STATE } from 'constants/expense';
import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import ExpenseRow from './NewExpenseRow';
import ExpenseState from '../../states/ExpenseState';
import NumberFormat from '../../../helpers/NumberFormat';

const FalseInput = ({ label, value, alignRight }) => {
  if (!value) {
    return (
      <Typography
        style={{
          color: 'lightgray',
          height: '50px',
          display: 'flex',
          justifyContent: alignRight ? 'flex-start' : 'center',
          alignItems: 'center',
          overflow: 'hidden',
          width: '100%',
          borderBottom: '1px lightgray solid',
          marginBottom: '3px',
        }}
      >
        {label}
      </Typography>
    );
  }
  return (
    <div
      style={{
        height: '50px',
        overflow: 'hidden',
        width: '100%',
        borderBottom: '1px lightgray solid',
        marginBottom: '3px',
      }}
    >
      <div style={{ color: 'lightgray' }}>{label}</div>
      <Typography
        style={{
          display: 'flex',
          justifyContent: alignRight ? 'flex-start' : 'center',
          alignItems: 'center',
          height: '80%',
          marginLeft: '5px',
        }}
      >
        {value}
      </Typography>
    </div>
  );
};

FalseInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  alignRight: PropTypes.bool,
};

const ReadOnlyTable = ({
  expense,
  users,
  fetchExpenseFile,
  categories,
  activities,
  clients,
  clientProjects,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();

  function renderGroupExpense() {
    return expense.expenses.map((exp, i) => (
      <ExpenseRow
        index={i}
        key={exp._id}
        expense={exp}
        users={users}
        fetchExpenseFile={fetchExpenseFile}
        activities={activities}
        clients={clients}
        clientProjects={clientProjects}
        categories={categories}
      />
    ));
  }

  function renderExpense() {
    let name;
    if (users && users[expense.user_id]) {
      name = users[expense.user_id].display_name;
    }
    switch (expense.__t) {
      case 'GroupedExpense': {
        const calculateTotal = expense.expenses
          .map(
            (oneExpense) =>
              +oneExpense.duePayableAmount ||
              oneExpense.grandTotalAmount ||
              oneExpense.total
          )
          .reduce((a, b) => a + b, 0);
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    {name ? (
                      <FalseInput label={t('expense.list.name')} value={name} />
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ExpenseState
                      state={t(
                        `expenses.expense.state.${expense.displayState}`
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {expense.validated_at ? (
                      <FalseInput
                        label={t('expenses.expense.validated_at')}
                        value={format(
                          new Date(expense.validated_at),
                          'yyyy-MM-dd'
                        )}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <FalseInput
                        label={t('expenses.expense.form.total_gross')}
                        value={`${NumberFormat.n(calculateTotal)} €`}
                      />
                      <CurrencyTextField
                        name={'deductibleAmount'}
                        label={t('expenses.expense.form.to_deducted')}
                        variant="standard"
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value >= 0 && value <= values.remainToPay) {
                            setFieldValue('deductibleAmount', e.target.value);
                          }
                        }}
                        disabled={values.state !== EXPENSE_STATE.DRAFT}
                      />
                      <FalseInput
                        label={t('expenses.expense.form.net_to_pay')}
                        value={`${NumberFormat.n(
                          Math.max(0, calculateTotal - values.deductibleAmount)
                        )} €`}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {expense?.refuseMessage && (
              <>
                <br />
                <Typography variant="subtitle1">{`${t(
                  'expenses.expense.form_reason_reject'
                )}: ${expense?.refuseMessage}`}</Typography>
              </>
            )}
            <br />
            <br />
            {renderGroupExpense(expense)}
          </div>
        );
      }
      case 'SimpleExpense':
        return (
          <ExpenseRow
            categories={categories}
            expense={expense}
            users={users}
            fetchExpenseFile={fetchExpenseFile}
          />
        );

      default:
        return null;
    }
  }

  return <div>{renderExpense()}</div>;
};

ReadOnlyTable.propTypes = {
  expense: PropTypes.object.isRequired,
  users: PropTypes.object,
  fetchExpenseFile: PropTypes.func.isRequired,
  activities: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  clientProjects: PropTypes.object.isRequired,
};

export default ReadOnlyTable;
