import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appDialog';
import '../../assets/css/icons.css';
import AppButton from '../AppButton';
import { IconClose } from '../AppIcons';

const AppDialog = ({
  title,
  iconClose,
  children,
  classes,
  color,
  colorConfirm,
  closeDialog,
  overflowScroll,
  setWidthManualy,
  setHeightManualy,
  footer,
  onConfirmText,
  onCancelText,
  contentText,
  onConfirm,
  sm,
  center,
  right,
  contentCustomStyles,
  titleCustomStyles,
  buttonCustomStyles,
  dialogStyles,
}) => {
  const dialogClasses = classNames({
    [classes.dialog]: true,
  });
  const mainContainerClasses = classNames({
    [classes.mainContainer]: true,
    [classes[`${color}Border`]]: color,
    [classes.mainContainerSm]: sm,
    // [classes[`${color}Border`]]: color,
  });
  const headerClasses = classNames({
    [classes.header]: true,
    [classes.headerSm]: sm,
  });
  const titleClasses = classNames({
    [classes.title]: true,
  });
  const contenetContainerClasses = classNames({
    [classes.contentContainer]: true,
    [classes.overflowScroll]: overflowScroll,
  });

  const footerClasses = classNames({
    [classes.footer]: footer,
    [classes.footerSm]: sm,
    [classes.footerCenter]: center,
    [classes.footerRight]: right,
  });

  const setWidth = setWidthManualy ? { width: setWidthManualy } : null;
  const setHeight = setHeightManualy ? { height: setHeightManualy } : null;

  return (
    <div className={dialogClasses}>
      <div
        className={mainContainerClasses}
        style={{ ...setHeight, ...setWidth, ...dialogStyles }}
      >
        <div className={headerClasses}>
          {title && (
            <p style={titleCustomStyles} className={titleClasses}>
              {title}
            </p>
          )}
          {iconClose && <IconClose onClick={closeDialog} sm secondary />}
        </div>
        <div className={contenetContainerClasses}>
          {contentText ? (
            <p style={contentCustomStyles} className={classes.content}>
              {contentText}
            </p>
          ) : (
            children
          )}
        </div>
        {footer && (
          <div className={footerClasses}>
            {onCancelText ? (
              <AppButton
                color="transparentGrey"
                text={onCancelText}
                onClick={closeDialog}
                style={buttonCustomStyles}
              />
            ) : (
              ''
            )}

            <AppButton
              color={colorConfirm}
              text={onConfirmText}
              noBorder={true}
              onClick={() => onConfirm()}
              style={buttonCustomStyles}
            />
          </div>
        )}
      </div>
    </div>
  );
};

AppDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  color: PropTypes.string,
  colorConfirm: PropTypes.string,
  onConfirmText: PropTypes.string,
  iconClose: PropTypes.bool,
  footer: PropTypes.bool,
  sm: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  onCancelText: PropTypes.string,
  overflowScroll: PropTypes.bool,
  title: PropTypes.string,
  contentText: PropTypes.string,
  setWidthManualy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHeightManualy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentCustomStyles: PropTypes.object,
  titleCustomStyles: PropTypes.object,
  buttonCustomStyles: PropTypes.object,
  dialogStyles: PropTypes.object,
};
AppDialog.defaultProps = {
  color: 'primary',
  colorConfirm: 'secondaryLight',
  onCancelText: null,
  contentText: null,
  iconClose: false,
  overflowScroll: false,
  footer: false,
  sm: false,
  title: null,
  onConfirm: null,
  onConfirmText: null,
  setWidthManualy: null,
  setHeightManualy: null,
  contentCustomStyles: {},
  titleCustomStyles: {},
  buttonCustomStyles: {},
  dialogStyles: {},
};

export default withStyles(style)(AppDialog);
