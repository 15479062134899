/* eslint-disable no-plusplus */

import { getCategoryId, getSubCategoryId } from 'helpers/providerHelpers';
import { xlsxToJson } from 'helpers/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { validator } from 'utils/validate';

export class Provider {
  constructor(index, row) {
    Provider.keys = [
      'siret',
      'name',
      'address',
      'zipcode',
      'city',
      'country',
      'category',
      'subCategory',
      'activity',
      'isVAT',
      'intracomvat',
      'title',
      'nomDuContact',
      'phone',
      'email',
    ];

    this.index = index;

    this.isCompany = true;

    // value selected in the table
    this.isSelected = false;

    Provider.keys.forEach((key, i) => {
      this[key] = row[i] && typeof row[i] === 'string' ? row[i].trim() : row[i];
      if (key === 'country' && !row[i]) this[key] = 'FR';
    });

    if (this.nomDuContact) {
      this.contactName = this.nomDuContact;
    }
  }

  static toJson(data, getActivityId) {
    const newProvider = { ...data };
    console.log('🚀 ~ Provider ~ toJson ~ data:', data);
    newProvider.activity = getActivityId(data.activity);
    return {
      ...newProvider,
      address: {
        address: data.address,
        zipcode: data.zipcode,
        city: data.city,
        country: data.country || 'FR',
      },
      contact: {
        contactName: data.contactName,
        title: data.title,
        email: data.email,
        phone: data.phone,
      },
    };
  }

  get isValidated() {
    return this._isValidated;
  }

  set isValidated(value) {
    this._isValidated = !!value;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
  }

  /**
   * @param {boolean} value
   */
  setSelected(value) {
    this.isSelected = !!value;
  }
}

const useImportProviders = () => {
  const [providerList, setProviderList] = useState([]);
  const categories = useSelector((state) => state.categories);
  const activities = useSelector((state) => state.activities);
  const activityIds = Object.keys(activities);
  const { t } = useTranslation();

  const checkValidateData = useCallback(
    (dataInput = [], isSetData = true) => {
      const _validator = {
        siret: [validator.siret, validator.unique],
        name: [validator.required],
        address: [validator.required],
        zipcode: [validator.required],
        city: [validator.required],
        country: [validator.required],
        category: [validator.required],
        subCategory: [validator.required],
        activity: [],
        title: [],
        nomDuContact: [],
        phone: [validator.phone],
        email: [validator.email],
        iban: [validator.iban],
      };

      const dataValidated = [];

      dataInput.forEach((item) => {
        let isValidated = true;

        Object.keys(_validator).forEach((key) => {
          const value = item[key];

          // eslint-disable-next-line no-bitwise
          isValidated &= _validator[key].every((func) => {
            if (!func?.length) return true;
            if (func.name === 'validateUnique') {
              return func(value, dataValidated, key);
            }
            if (key === 'email') {
              if (!value) return true;
              return func(value, dataValidated, key);
            }
            return func(value);
          });
        });

        // eslint-disable-next-line no-param-reassign
        item.isValidated = !!isValidated;

        if (isValidated) {
          dataValidated.push(item);
        }
      });

      if (isSetData) {
        setProviderList(() => dataInput);
      }
    },
    [providerList, setProviderList]
  );

  const onFileChange = async (files) => {
    if (
      files &&
      files.length &&
      files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const jsonData = await xlsxToJson(files[0]);

      if (!jsonData.length) {
        return [];
      }

      const handleMapKeys = (data) => {
        return data.map((item, idx) => new Provider(idx, item));
      };

      const dataResult = handleMapKeys(jsonData);
      const listActivity = activityIds.map((item) => activities[item]);

      for (let i = 0; i < dataResult.length; i++) {
        const existedActivity = listActivity.find(
          (activity) =>
            activity.display === dataResult[i].activity ||
            activity._id === dataResult[i].activity
        );
        if (existedActivity) {
          // eslint-disable-next-line no-param-reassign
          dataResult[i].activity = existedActivity._id;
        }
        dataResult[i].category = getCategoryId(dataResult[i], categories);
        dataResult[i].subCategory = getSubCategoryId(
          dataResult[i],
          categories,
          t
        );
      }

      checkValidateData(dataResult, false);

      setProviderList(() => dataResult);

      return dataResult;
    }
    return null;
  };

  return {
    onFileChange,
    providers: { providerList, setProviderList },
    checkValidateData,
  };
};

export default useImportProviders;
