import { format } from 'date-fns';

export const amount = () => ({
  amount: 0,
  total: 0,
  vat: 0,
  vat_rate: 0,
  category_id: null,
  sub_category_id: null,
});

const amountModel = () => {
  return [amount()];
};

const internalInitPurcahse = (
  companyId,
  categoryId,
  purchase = {},
  subCategory
) => {
  const paymentType = 'company2';
  return {
    action: null,
    company_id: purchase.company_id || companyId,
    date: purchase.date || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    due_date:
      purchase.due_date || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    invoice_nb: purchase.invoice_nb || '',
    provider: purchase.provider || null, // null value as ObjectId in db
    client_id: purchase.client_id || null, // null value as ObjectId in db
    project_id: purchase.project_id || null, // null value as ObjectId in db
    internal_activity: purchase.internal_activity || '',
    category_id: categoryId,
    category_display: '',
    sub_category_id: subCategory?._id || null,
    sub_category_display: '',
    description: purchase.description || '',
    buyerOrderReferencedDocument: purchase.buyerOrderReferencedDocument || '',
    amounts: purchase.amounts || amountModel(),
    taxBasisTotalAmount: purchase.taxBasisTotalAmount || 0,
    taxTotalAmount: purchase.taxTotalAmount || 0,
    grandTotalAmount: purchase.grandTotalAmount || 0,
    duePayableAmount: purchase.duePayableAmount || 0,
    state: purchase.state || null,
    filename: purchase.filename || null,
    payment_type: purchase.payment_type || paymentType,
    billNumber: '',
    orderNumber: '',
    providerName: '',
    reference: '',
    providerSiret: '',
    is_intern: true,
  };
};

export const initializePurchase = (categoryId, company, expenses, id) => {
  const { _id: companyId } = company;
  const purchase = id && expenses[id] ? expenses[id] : {};
  const category = purchase.category_id || categoryId;
  const subCategory = purchase.sub_category_id || null;
  return internalInitPurcahse(companyId, category, purchase, subCategory);
};
