import { getErrKey } from 'helpers/errorHelpers';
import spinnerService from 'services/SpinnerService';
import { ROLE } from '../../../constants/roles';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const {
    companySubscription: {
      data: { userLimited },
    },
    openAddUserFormDialog,
    companyAccessRights: { roles },
  } = props;
  const {
    admin: remainingAdmin,
    expertComptable: remainingUserExpert,
    additionalUsers: remainingUser,
  } = userLimited?.remainingQuota || {};
  const remainingUserInvitations = userLimited?.remaining;

  const fetchData = () => {
    spinnerService.startSpinner();
    Promise.all([
      props.fetchCompanyUsers(),
      props.fetchCompanySubscription(),
    ]).finally(() => spinnerService.endSpinner());
  };
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t(getErrKey(err?.message)),
      });
    } else {
      setStatus({ success: true });

      if (props.closeDialog) {
        props.closeDialog();
        fetchData();
      } else {
        setSubmitting(false);
      }
    }
  };
  const getRoleId = (roleName) => {
    return roles?.find((item) => item.role === roleName)?._id;
  };

  if (!values._id) {
    const userIsAdmin = values?.role?.includes(getRoleId(ROLE.ADMINISTRATEUR));
    const userIsExpert = values?.role?.includes(
      getRoleId(ROLE.EXPERT_COMPTABLE)
    );
    const canCreateUser =
      (userIsAdmin && remainingAdmin > 0) ||
      (userIsExpert && remainingUserExpert > 0) ||
      (!userIsExpert && !userIsAdmin && remainingUser > 0) ||
      remainingUserInvitations > 0;

    if (canCreateUser) {
      props.createUser(values, cb);
      return;
    }
    setSubmitting(false);
    openAddUserFormDialog(values);
  } else {
    props.updateUser({ ...values, avatar: undefined }, cb);

    if (props.loggedUser.user._id === values._id && values.language) {
      props.changeLanguage(values.language);
    }
  }
};
