/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { IconDelete, IconEdit } from 'components/AppIcons';
import { useRouter } from 'hooks/routerHooks';

import NumberFormat from 'helpers/NumberFormat';
import { getExpenseURL } from 'helpers/expenseHelpers';
import { CLEAR_EXPENSE } from 'actions/Types';

import { userCanUpdateExpense } from 'selectors/rightsSelector/expenseRequests';
import {
  userCanReadAdvanceExpense,
  userCanUpdateAdvanceExpense,
} from 'selectors/rightsSelector/advanceExpenseRequests';

import MaterialTable from 'components/MaterialTable';
import { fetchExpensePaginate } from 'actions/ExpensesActions';
import { Refresh } from '@material-ui/icons';
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Button,
} from '@material-ui/core';
import HorizontalBeforAfterDate from 'components/HorizontalBeforeAfterDate';
import queryString from 'query-string';
import { AppRowStartSpaceAround } from 'components/AppContainers';
import ConfirmDialog from 'components/ConfirmDialog';
import { exportToCSV } from 'helpers/fileHelper';
import { EXPENSE_STATE } from 'constants/expense';
import ExpenseService from 'services/ExpenseService';
import spinnerService from 'services/SpinnerService';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import ExpenseState from '../../../components/states/ExpenseState';

const expenseStates = [
  'draft',
  'canceled',
  'validated',
  'paid',
  'payoutpending',
];

const ExpenseListBody = ({ canReadExpense, refreshing }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  const { t } = useTranslation();
  const { history, location } = useRouter();
  const dispatch = useDispatch();

  const tableRef = React.createRef();

  const users = useSelector((state) => state.users);
  const categories = useSelector((state) => state.categories);
  const clientProjects = useSelector((state) => state.clientProjects.projects);
  const activitiesReduxStore = useSelector((state) => state.activities);

  const filters = queryString.parse(location?.search, { arrayFormat: 'index' });
  localStorage.removeItem('expenseFilters');
  const [before, setBefore] = useState(filters ? filters.before || '' : '');
  const [after, setAfter] = useState(filters ? filters.after || '' : '');
  const [expenseState, setExpenseState] = useState(
    filters ? filters.state || [] : []
  );

  /* eslint-disable */
  const [clientIdFilters, setFilterId] = useState(
    filters ? filters.client_id || '' : ''
  );

  const [projects, setProjects] = useState([]);

  const [selectedExpense, setSelectedExpense] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const canUpdateExpense = useSelector(userCanUpdateExpense);
  const canReadAdvanceExpens = useSelector(userCanReadAdvanceExpense);
  const canUpdateAdvanceExpense = useSelector(userCanUpdateAdvanceExpense);

  const [refresh, setRefresh] = useState(false);

  const [queryTable, setQueryTable] = useState({});

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  }

  const clearFilters = () => {
    setBefore('');
    setAfter('');
    setExpenseState([]);
    fetch();
  };

  useEffect(() => {
    getClientProjects();
    if (refresh) {
      fetch();
    }
  }, [refresh]);
  function renderType(value) {
    switch (value) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return t('expenses.expense.type.note');
      case 'AdvancedExpense':
        return t('expenses.expense.type.advance');
      case 'Purchase':
      case 'InvoiceExpense':
        return t('expenses.expense.type.invoice');
      default:
        return 0;
    }
  }

  const getClientProjects = () => {
    const projects = [];
    const clientsId = Object.keys(clientProjects);
    clientsId.map((clientId) => {
      clientProjects[clientId].map((project) => projects.push(project));
    });
    setProjects([...projects]);
  };

  function renderEntity(exp) {
    if (exp.__t === 'AdvancedExpense') {
      return users[exp.payee_id]
        ? users[exp.payee_id].display_name
        : t('not_setted');
    }

    return users[exp.user_id]
      ? users[exp.user_id].display_name
      : t('not_setted');
  }

  function renderTotal(exp) {
    switch (exp.__t) {
      case 'AdvancedExpense': {
        return `${NumberFormat.n(exp.total)}€` || `${t('not_setted')}`;
      }
      case 'GroupedExpense': {
        const calculateTotal = exp.expenses
          .map((oneExpense) => oneExpense.grandTotalAmount || oneExpense.total)
          .reduce((a, b) => a + b, 0);
        return `${NumberFormat.n(calculateTotal)}€` || `${t('not_setted')}`;
      }
      case 'SimpleExpense': {
        return (
          `${NumberFormat.n(exp.grandTotalAmount)}€` || `${t('not_setted')}`
        );
      }
      case 'InvoiceExpense':
      case 'Purchase':
        return (
          `${NumberFormat.n(exp.duePayableAmount)}€` || `${t('not_setted')}`
        );
      default:
        return 0;
    }
  }

  function renderToCash(exp) {
    let duePayableAmount = 0;

    if (!exp.expenses) {
      duePayableAmount = exp.duePayableAmount || 0;
    } else if (exp.state === EXPENSE_STATE.DRAFT) {
      duePayableAmount = exp.expenses
        ?.map((oneExpense) => +oneExpense.duePayableAmount)
        .reduce((a, b) => a + b, 0);
    } else {
      duePayableAmount = exp.duePayableAmount;
    }

    if (exp.state === 'paid' || exp.state === 'canceled') {
      duePayableAmount = 0;
    }

    return `${+duePayableAmount.toFixed(2)}€`;
  }

  function renderCategory(exp) {
    let catId = null;

    switch (exp.__t) {
      case 'GroupedExpense':
        catId = exp.expenses[0].category_id;
        break;
      case 'SimpleExpense':
      case 'InvoiceExpense':
      case 'Purchase':
        catId = exp.category_id;
        break;
      default:
        catId = null;
    }

    return catId && categories.data && categories.data[catId]
      ? categories.data[catId].description
      : '';
  }

  const setTableProject = (exp) => {
    if (exp.expenses) {
      const projectsId = exp.expenses.map((item) => item.project_id);
      const result = projectsId.filter((item) => item !== null);
      return result.length !== 0 ? result : 'N/A';
    }
    return 'N/A';
  };

  const setTableActivity = (exp) => {
    if (exp.expenses) {
      const activityId = exp.expenses.map((item) => item.internal_activity);
      const result = activityId.filter((item) => item !== null);
      return result.length !== 0 ? result : 'N/A';
    }
  };

  const formatData = (data) => {
    return data.map((exp) => {
      return {
        ...exp,
        category: renderCategory(exp),
        type: renderType(exp.__t),
        expense_nb: exp.expense_nb,
        state: t(`expenses.expense.state.${exp.state}`),
        canDelete: exp.state === 'draft',
        date: exp.date,
        entity: renderEntity(exp),
        gross_total: renderTotal(exp),
        to_pay: renderToCash(exp),
        projectId: setTableProject(exp),
        internalActivity: setTableActivity(exp),
        id: exp._id,
        paymentDate:
          exp.transactions.length !== 0 ? exp.transactions[0].date : 'N/A',
      };
    });
  };

  function editPath(expense) {
    const url =
      expense.__t === 'GroupedExpense'
        ? getExpenseURL(expense.expenses[0].payment_type)
        : getExpenseURL(expense.payment_type);

    switch (expense.__t) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return {
          pathname: `/${url}/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };
      case 'AdvancedExpense':
        return `/expenses/advance/edit/${expense._id}`;
      case 'InvoiceExpense':
      case 'Purchase':
        return {
          pathname: `/purchases/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };

      default:
        return '/';
    }
  }

  const columns = [
    {
      title: t('expenses.list.type'),
      field: 'type',
      sorting: false,
    },
    {
      title: t('expenses.list.state'),
      field: 'state',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: ({ state }) => <ExpenseState state={state} />,
    },
    {
      title: t('expenses.list.nb'),
      field: 'expense_nb',
    },
    {
      title: t('expenses.list.form_date'),
      field: 'date',
      defaultSort: 'desc',
      render: ({ date }) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: t('expenses.list.user'),
      field: 'entity',
      sorting: false,
    },
    {
      title: t('expenses.list.internal_activity'),
      field: 'internal_activity',
      sorting: false,
      render: ({ internal_activity }) => {
        if (internal_activity !== 'N/A') {
          const result =
            activitiesReduxStore[internal_activity]?.display || 'N/A';
          return result;
        }
        return 'N/A';
      },
    },
    {
      title: t('expenses.list.project'),
      field: 'projectId',
      sorting: false,
      render: ({ projectId }) => {
        if (projectId !== 'N/A') {
          return (
            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
              {projectId.map((item) => {
                const result =
                  projects.find((project) => project._id === item)?.name ||
                  'N/A';
                return (
                  <div
                    style={{
                      borderRadius: '5px',
                      backgroundColor: result !== 'N/A' ? '#3d319d' : '',
                      color: 'white',
                      padding: '5px 10px',
                      fontWeight: 'bold',
                    }}
                  >
                    {result}
                  </div>
                );
              })}
            </div>
          );
        }
        return (
          <div style={{ display: 'flex', gap: '5px' }}>
            <div
              style={{
                borderRadius: '5px',
                // backgroundColor: 'red',
                // color: 'white',
                // padding: '5px 10px',
                fontWeight: 'bold',
              }}
            >
              N/A
            </div>
          </div>
        );
      },
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'gross_total',
    },
    {
      title: t('expenses.expense.remainToPay'),
      field: 'remainToPay',
      render: ({ remainToPay }) => {
        return remainToPay || remainToPay === 0
          ? `${NumberFormat.n(remainToPay)}€`
          : 'n/a';
      },
    },
    {
      title: t('expenses.expense.form_to_pay'),
      field: 'to_pay',
    },
    {
      title: t('expenses.expense.form_payment_date'),
      field: 'paymentDate',
      render: ({ paymentDate }) => {
        if (paymentDate !== 'N/A') {
          return format(new Date(paymentDate), 'dd/MM/yyyy');
        }
      },
    },
    {
      title: ' ',
      field: 'id',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: (value) => {
        const { __t: expenseType, canDelete } = value;

        const canSeeExpensePage = canUpdateExpense || canReadExpense;
        const canSeeAdvancedExpensePage =
          canReadAdvanceExpens || canUpdateAdvanceExpense;

        const userCanSeeButton =
          (['GroupedExpense', 'SimpleExpense', 'Purchase'].includes(
            expenseType
          ) &&
            canSeeExpensePage) ||
          (expenseType === 'AdvancedExpense' && canSeeAdvancedExpensePage);

        if (!userCanSeeButton) {
          return null;
        }

        return (
          <AppRowStartSpaceAround>
            <IconEdit
              disabled={refreshing}
              sm
              onClick={() => {
                dispatch({ type: CLEAR_EXPENSE });
                history.push(editPath(value));
              }}
            />
            {canDelete && (
              <IconDelete
                sm
                onClick={() => {
                  setOpenConfirm(true);
                  setSelectedExpense(value);
                }}
              />
            )}
          </AppRowStartSpaceAround>
        );
      },
      export: false,
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
      },
    },
  ];

  function renderToolBar() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        style={{
          position: 'relative',
          paddingBottom: '30px',
          paddingTop: '10px',
        }}
      >
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {t('invoices.list.state')}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={expenseState}
              onChange={(event) => setExpenseState(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((s) => t(`expenses.expense.state.${s}`)).join(', ')
              }
            >
              {expenseStates.map((state) => (
                <MenuItem key={state} value={state}>
                  <Checkbox checked={expenseState.indexOf(state) > -1} />
                  <ListItemText
                    primary={t(`expenses.expense.state.${state}`)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <HorizontalBeforAfterDate
            onAfterChange={(value) => {
              setAfter(value);
              fetch();
            }}
            onBeforeChange={(value) => {
              setBefore(value);
              fetch();
            }}
            after={after}
            before={before}
            beforeTitle={t('invoice.filters.before')}
            afterTitle={t('invoice.filters.after')}
          />
        </Grid>

        <Grid item style={{ position: 'absolute', right: '0' }}>
          <Tooltip title={t('reset_filter')}>
            <Button startIcon={<Refresh />} onClick={() => clearFilters()}>
              {t('reset_filter')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const handleFetchExpenses = (query) => {
    return new Promise((resolve) => {
      dispatch(
        fetchExpensePaginate(
          query,
          {
            type: 'expense',
            state: expenseState,
            before: before ? `${before}T23:59:59.999Z` : '',
            after,
            client_ids: clientIdFilters,
          },
          refresh,
          false
        )
      ).then((res) => {
        setRefresh(false);
        resolve({ ...res, data: formatData(res.data) || [] });
      });
    });
  };

  const formatExpensesToExport = (data = []) => {
    const formatData = data?.map((item) => {
      const internal_activity = setTableActivity(item);
      const paymentDate =
        item.transactions.length !== 0 ? item.transactions[0].date : 'N/A';
      const newItem = {
        [t('expenses.list.type')]: renderType(item.__t),
        [t('expenses.list.state')]: item.state,
        [t('expenses.list.nb')]: item.expense_nb,
        [t('expenses.list.form_date')]: format(
          new Date(item.date),
          'dd/MM/yyyy'
        ),
        [t('expenses.list.user')]: renderEntity(item),
        [t('expenses.list.internal_activity')]:
          internal_activity !== 'N/A'
            ? activitiesReduxStore[internal_activity]?.display || 'N/A'
            : 'N/A',
        [t('expenses.list.project')]: item.projectId,
        [t('expenses.expense.form_amount')]: renderTotal(item),
        [t('expenses.expense.form_to_pay')]: renderToCash(item),
        [t('expenses.expense.form_payment_date')]:
          paymentDate !== 'N/A'
            ? format(new Date(paymentDate), 'dd/MM/yyyy')
            : '',
      };
      return newItem;
    });
    return formatData;
  };

  const handleDeleteDraft = async (isConfirm) => {
    if (isConfirm)
      try {
        spinnerService.startSpinner();
        await ExpenseService.deleteExpense(selectedExpense.id);
        setRefresh(true);
      } catch (error) {
        throw new Error(error);
      } finally {
        spinnerService.endSpinner();
      }
  };

  return (
    <Fragment>
      <ConfirmDialog
        toggleDialog={setOpenConfirm}
        confirmMessage={t('expenses.expense.confirm_delete_text')}
        isShowDialog={openConfirm}
        // type={confirmType}
        onConfirm={handleDeleteDraft}
        isCancelConfirm={true}
      />
      {renderToolBar()}
      <MaterialTable
        tableRef={tableRef}
        data={(query) => {
          setQueryTable(query);
          return handleFetchExpenses(query);
        }}
        columns={columns}
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
        }}
        options={{
          filtering: false,
          search: true,
          showTitle: false,
          pageSize,
          exportButton: { csv: true },
          exportCsv: async () => {
            const result = await handleFetchExpenses({
              ...queryTable,
              pagination: false,
            });
            const formatData = formatExpensesToExport(result?.data);
            exportToCSV(formatData, 'data');
          },
        }}
        actions={[
          {
            icon: () => <Refresh />,
            isFreeAction: true,
            onClick: () => setRefresh(true),
          },
        ]}
      />
    </Fragment>
  );
};

ExpenseListBody.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  canReadExpense: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default ExpenseListBody;
