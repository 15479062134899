import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import TextField from 'components/FormElements/textField';
import { Divider } from '@material-ui/core';
import PurchaseVATGroup from './PurchaseVATGroup';

const PurchasePriceItem = ({ defaultTaxes, readOnly, categories }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  return (
    <Fragment>
      <TextField
        required={values?.state}
        multiline
        type="text"
        name={'description'}
        label={t('expenses.expense.form_description')}
        placeholder={t('expenses.expense.form_description')}
        fullWidth
      />
      <Divider style={{ margin: '10px 0' }} />
      <PurchaseVATGroup
        readOnly={readOnly}
        defaultTaxes={defaultTaxes}
        categories={categories}
      />
    </Fragment>
  );
};

PurchasePriceItem.propTypes = {
  defaultTaxes: PropTypes.array,
  readOnly: PropTypes.bool,
  isTravelWithDistanceRate: PropTypes.bool,
  categories: PropTypes.array,
};

export default React.memo(PurchasePriceItem);
