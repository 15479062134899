/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
/* import packages */
import { useSelector, useDispatch } from 'react-redux';
import _, { filter, sortBy } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEuProvider, isNonEuProvider } from 'helpers/providerHelpers';
/* import action */
import { storeScanOCR } from 'actions/ScanOCRActions';

import CreateProviderButton from 'containers/purchase/PurchaseForm/Sidebar/CreateProviderButton';
import { useCreateDialog } from 'hooks/dialogHooks';
import { ProviderFormDialog } from 'containers/purchase/PurchaseForm/Dialogs';
import TextField from 'components/FormElements/textField';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import { EXPENSE_TYPE, KM_CATEGORY_ID } from 'constants/expense';
import SelectAutocomplete from '../../SelectAutocomplete';

const ProviderInfo = ({ type }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  // const categories = useSelector((state) => state.categories);
  const tree = useSelector((state) => state.categories.tree || []);
  const [showCreateProviderBtn, setShowCreateProviderBtn] = useState(false);
  const { t } = useTranslation();
  const providers = useSelector((state) => state.providers);

  const {
    showDialog: showNewProviderDialog,
    toggleDialog: toggleNewProviderDialog,
    newItem: newProvider,
    setNewItem: setNewProvider,
    removeNewItem: removeNewProvider,
  } = useCreateDialog();

  const { provider, providerSiret, billNumber } = values;

  const handleFindProvider = () => {
    const foundProviderBySiret = _.values(providers).filter(
      (item) => item.siret === providerSiret
    );

    if (foundProviderBySiret.length) {
      setFieldValue('provider', foundProviderBySiret[0]._id);
      setFieldValue('providerName', foundProviderBySiret[0]?.name);
      setFieldValue(
        'description',
        `${foundProviderBySiret[0]?.name}${
          billNumber ? ` N° ${billNumber}` : ''
        }`
      );
    }

    setShowCreateProviderBtn(!foundProviderBySiret.length && !values?.provider);
  };

  const filteredTree = useMemo(() => {
    // Finding the target category by its _id
    const targetCategory = tree.find(
      (category) => category._id === DEFAULT_CATEGORY_ID
    );
    // Filtering the children of targetCategory based on travel.isTravelWithDistanceRate property
    const filteredChildren = targetCategory?.children?.filter(
      (category) => !category.travel.isTravelWithDistanceRate
    );
    // Filtering the tree based on order property
    const filteredTreeData = tree.filter((category) => category.order > 0);

    // Merging the filteredChildren with filteredTree and mapping to include only display and children properties
    const mappingCategories = [
      ...(filteredChildren || []),
      ...filteredTreeData,
    ];

    // Modify categories for Expense form
    const removedCategories = [
      'Salaire et charges',
      'Banque',
      'Dirigeant',
      'Taxes',
    ];

    const addedCategories = [
      {
        display: 'Indemnités kilométriques',
        _id: KM_CATEGORY_ID,
        children: [],
      },
    ];

    return [...mappingCategories, ...addedCategories]
      .filter((category) => removedCategories.indexOf(category.display) === -1)
      .map((item) => ({
        ...item,
        display: t(`expenses.categories.${item.display}`),
      }));
  }, [tree]);

  // useEffect(() => {
  //   if (providerSiret?.length === 14) {
  //     handleFindProvider();
  //   } else {
  //     setShowCreateProviderBtn(false);
  //     // setFieldValue('provider', '');
  //   }
  // }, [providerSiret, provider]);

  function changeProvider(e) {
    if (e) {
      setFieldValue('provider', e._id);
      setFieldTouched('provider', true);
      setFieldValue('providerSiret', providers[e._id].siret);
      setFieldValue('providerName', providers[e._id].name);
      setFieldValue(
        'description',
        `${providers[e._id].name}${billNumber ? ` N° ${billNumber}` : ''}`
      );
      setFieldValue('internal_activity', providers[e._id].activity);

      // categories
      const curProvider = providers[e._id];

      // set category for amount element
      if (type === EXPENSE_TYPE.PURCHASE) {
        const { amounts } = values;
        amounts.forEach((amount, index) => {
          setFieldValue(`amounts.${index}.category_id`, curProvider.category);
          setFieldValue(
            `amounts.${index}.sub_category_id`,
            curProvider.subCategory
          );
        });
      }

      const parentCategories = filteredTree.find((category) => {
        const providerTranslation = t(
          `expenses.categories.${curProvider.category}`
        );
        const providerCategory = providerTranslation.includes(
          'expenses.categories'
        )
          ? curProvider.category
          : providerTranslation;

        const categoryTranslation = t(
          `expenses.categories.${category.display}`
        );

        const categoryDisplay = categoryTranslation.includes(
          'expenses.categories'
        )
          ? category.display
          : categoryTranslation;

        return (
          category._id === providerCategory ||
          categoryDisplay === providerCategory
        );
      });

      setFieldValue('import_category', parentCategories._id);
      setFieldValue('import_categoryDisplay', parentCategories.display);
      setFieldValue('import_categoryChildren', parentCategories.children);
      setFieldValue('import_subCategory', curProvider.subCategory);
      setFieldValue('category_id', curProvider.subCategory);
    } else {
      setFieldValue('provider', '');
      setFieldTouched('provider', false);
      setFieldValue('providerSiret', '');
      setFieldValue('providerName', '');
      setFieldValue(
        'description',
        `${billNumber !== '' ? ` N° ${billNumber}` : ''}`
      );
    }
  }

  useEffect(() => {
    const curProvider = providers[values?.provider];
    if (!curProvider) {
      return;
    }
    const updatePurchaseAmounts = (amounts) => {
      amounts.forEach((item, index) => {
        if (type === EXPENSE_TYPE.EXPENSE) {
          setFieldValue(`amounts.${index}.category_id`, curProvider.category);
          setFieldValue(
            `amounts.${index}.sub_category_id`,
            curProvider.subCategory
          );
        }
        if (isNonEuProvider(curProvider)) {
          setFieldValue(`amounts.${index}.vat`, 0);
          setFieldValue(`amounts.${index}.vat_rate`, 0);
          setFieldValue(
            `amounts.${index}.total`,
            parseFloat(item.amount).toFixed(2)
          );
        }
        if (isEuProvider(curProvider)) {
          setFieldValue(`amounts.${index}.vat`, 0);
          setFieldValue(
            `amounts.${index}.total`,
            parseFloat(item.amount).toFixed(2)
          );
        }
      });
    };

    const updateExpenseAmounts = (numberOfActiveVats) => {
      new Array(numberOfActiveVats).fill(0).forEach((item, index) => {
        if (isNonEuProvider(curProvider)) {
          setFieldValue(`vat${index}`, 0);
          setFieldValue(`vatrate${index}`, 0);
          setFieldValue(
            `total${index}`,
            parseFloat(values[`amount${index}`]).toFixed(2)
          );
        }
      });
    };

    if (type === EXPENSE_TYPE.PURCHASE && values?.amounts?.length > 0) {
      updatePurchaseAmounts(values.amounts);
    }

    if (values?.numberOfActiveVats > 0) {
      updateExpenseAmounts(values.numberOfActiveVats);
    }
  }, [type, values.provider, providers]);

  return (
    <React.Fragment>
      <SelectAutocomplete
        required={type !== EXPENSE_TYPE.PURCHASE ? true : values?.state}
        // readOnly={readOnly}
        name="provider"
        label={t('expenses.invoices.form_provider')}
        getOptionLabel={(elem) => elem.name}
        values={filter(sortBy(providers, 'name'), (o) => !o.deleted)}
        valueSelected={provider ? providers[provider] : null}
        onChange={changeProvider}
      />
      <TextField
        id="providerSiret"
        type="text"
        name="providerSiret"
        label={'Siret'}
      />

      {showCreateProviderBtn && (
        <div
          onClick={() => {
            dispatch(
              storeScanOCR({
                siret: providerSiret,
                isAutoCreateProvider: true,
              })
            );
          }}
        >
          <CreateProviderButton
            newProvider={{ provider: newProvider, removeNewProvider }}
            toggleNewProviderDialog={toggleNewProviderDialog}
          />
        </div>
      )}

      <ProviderFormDialog
        showNewProviderDialog={showNewProviderDialog}
        toggleNewProviderDialog={toggleNewProviderDialog}
        setNewProvider={setNewProvider}
      />
    </React.Fragment>
  );
};

ProviderInfo.propTypes = {
  type: PropTypes.string,
};

export default ProviderInfo;
