/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { filter, sortBy } from 'lodash';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import { SCAN_FILE_TYPE_PURCHASE } from 'constants/account';

import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import useImportPurchase from 'containers/ProvidersPage/hooks/useImportPurchase';
import UploadFile from './index';

import s3FileService from '../../services/S3FileService';

const useStyles = makeStyles(() => ({
  disableFiels: {
    pointerEvents: 'none',
    cursor: 'pointer',
    color: '#c4c4c4',
  },
}));

const UploadFileForm = ({
  readOnly,
  label,
  required,
  withOcr,
  defaultTaxes,
  isPreviewFile,
  disabled,
  toggleNewProviderDialog,
  toggleClientFormDialog,
  isPurchase = false,
  canAsyncScan = false,
  setOpenConfirmScan,
  onOcr = () => {},
  ocrError,
}) => {
  const { search } = window.location;
  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState(null);
  const [type, setType] = useState(null);
  const [loading, toggleLoading] = useState(false);
  const canScan = new URLSearchParams(search).get('canScan');

  const { t } = useTranslation();
  const { isSubmitting, errors, touched, setFieldValue, values } =
    useFormikContext();

  const {
    handleFileChange,
    handleImportXml,
    showScan,
    importError,
    setShowScan,
  } = useImportPurchase({
    values,
    setFieldValue,
    toggleNewProviderDialog,
    toggleClientFormDialog,
  });

  const { filename } = values;
  const categories = useSelector((state) => state.categories.data);
  const currentSubCategory = useMemo(
    () => categories[values.sub_category_id],
    [values.sub_category_id]
  );
  const extractFilename = new URLSearchParams(search).get('extractKey');

  async function getFileFromUrl(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type,
    });
  }

  useEffect(() => {
    (async () => {
      if (filename && typeof filename === 'string') {
        toggleLoading(true);
        await s3FileService
          .getObject(filename)
          .then((data) => {
            const blob = new Blob([Buffer.from(data.body)], {
              type: data.type,
            });
            const objectUrl = URL.createObjectURL(blob);

            setFileUrl(objectUrl);
            setType(data.type);
            toggleLoading(false);
          })
          .catch(() => {
            setFileUrl(null);
            setType(null);
            toggleLoading(false);
          });
      }
    })();
  }, [filename]);

  useEffect(() => {
    (async () => {
      if (extractFilename) {
        toggleLoading(true);
        await s3FileService
          .getObject(extractFilename)
          .then((data) => {
            setShowScan(true);
            const blob = new Blob([Buffer.from(data.body)], {
              type: data.type,
            });
            const objectUrl = URL.createObjectURL(blob);

            setFieldValue('filename', extractFilename);
            setFileUrl(objectUrl);
            setType(data.type);
            onOcr(blob);
            toggleLoading(false);
          })
          .catch(() => {
            setFileUrl(null);
            setType(null);
            toggleLoading(false);
          });
      }
    })();
  }, [extractFilename, currentSubCategory?.hasProvider]);

  function addReciept(file) {
    if (isPurchase) {
      if (file.type === 'text/xml') {
        handleImportXml(file);
        setFieldValue('filename', null);
        setFieldValue('fileContent', []);
        return;
      }

      handleFileChange(file);
    }
    setShowScan(true);
    setFieldValue('filename', file);
  }

  async function onOcrAsync() {
    if (canAsyncScan) setOpenConfirmScan(true);
    else onOcr();
  }

  return (
    <>
      <LoadingIndicatorDialog open={loading || values?.loading} />
      {importError && (
        <p style={{ textAlign: 'center', color: 'red', fontSize: 16 }}>
          {importError}
        </p>
      )}
      <UploadFile
        withOcr={(withOcr && showScan) || canScan}
        onOcr={onOcrAsync}
        ocrError={ocrError}
        label={label}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        mandatory
        name={`filename`}
        values={values}
        setFieldValue={setFieldValue}
        customWidth
        type={type}
        t={t}
        isSubmitting={isSubmitting}
        buttonClassName={`${isSubmitting ? classes.disableFiels : ''}`}
        fileUrl={fileUrl}
        accept={SCAN_FILE_TYPE_PURCHASE}
        multiple={false}
        showButton={false}
        style={{
          width: '100%',
          height: '100%',
        }}
        onFileSelected={addReciept}
        buttonText={t('signup.company.upload')}
        ocrText={t('ocr')}
        errors={errors}
        touched={touched}
        defaultIcon={
          <i
            style={{
              width: '100%',
              fontSize: '4.5em',
              margin: '8% 0',
              color: 'rgb(192,192,192)',
            }}
            className="fas fa-file-invoice-dollar"
          />
        }
        isPreviewFile={isPreviewFile}
        fileContent={values.fileContent}
      />
    </>
  );
};

UploadFileForm.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  withOcr: PropTypes.bool,
  defaultTaxes: PropTypes.array,
  isPreviewFile: PropTypes.bool,
  isPurchase: PropTypes.bool,
  toggleNewProviderDialog: PropTypes.func,
  toggleClientFormDialog: PropTypes.func,
  setOpenConfirmScan: PropTypes.func,
  onOcr: PropTypes.func,
  ocrError: PropTypes.object,
  canAsyncScan: PropTypes.bool,
};

export default UploadFileForm;
