/* eslint-disable import/prefer-default-export */
export const CORSE_VAT_RATES = [
  {
    value: 0.9,
    display: '0.9 %',
  },
  {
    value: 13,
    display: '13 %',
  },
];

export const OVERSEAS_VAT_RATES = [
  {
    value: 1.05,
    display: '1.05 %',
  },
  {
    value: 1.45,
    display: '1.45 %',
  },
  {
    value: 8.5,
    display: '8.5 %',
  },
];

export const DROM_VAT_RATES = {
  1.05: {
    value: 1.05,
    display: '1.05 %',
  },
  1.45: {
    value: 1.45,
    display: '1.45 %',
  },
  2.1: {
    value: 2.1,
    display: '2.1 %',
  },
  8.5: {
    value: 8.5,
    display: '8.5 %',
  },
};
