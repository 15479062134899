/* eslint-disable */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ErrorMessage from 'components/ErrorMessage';
import AppButton from '../AppButton';
import { AppDividerXS } from '../AppDividers';

import FilePreview from './FilePreview';
import FilePreviewDialog from './FilePreviewDialog';

import printErrors from '../../helpers/errorHelpers';

import { useThunkDispatch } from '../../hooks/generalHooks';

import colors from '../../constants/colors.json';

const useStyles = makeStyles(() => ({
  dropzone: {
    width: '100%',
    height: '45%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeDropzone: {
    backgroundColor: colors.light_grey,
    border: `dashed 1px ${colors.grey}`,
  },
  errorMessage: {
    color: colors.red,
  },
  wrapper: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  hideBtn: {
    display: 'none',
  },
  btnDropzone: {
    width: 'fit-content',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
}));

const UploadFile = (props) => {
  const {
    type: fileType,
    filename,
    onFileSelected,
    multiple,
    accept,
    values,
    buttonText,
    fetchCompanyLogo,
    fileUrl,
    label,
    required,
    readOnly,
    withOcr,
    onOcr,
    ocrText,
    ocrError,
    shape,
    customStyles,
    isPreviewFile,
    disabled,
    setFieldValue,
    fileContent,
  } = props;

  const { name, errors, touched } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const cacheId = urlParams.get('cacheId');
  const canScan = urlParams.get('canScan');

  const { t } = useTranslation();
  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const [logo, setLogo] = useState(null);
  const [type, setType] = useState(fileType);
  const [openDialog, setOpenDialog] = useState(false);
  const extractFilename = new URLSearchParams(window.location.search).get(
    'extractKey'
  );

  function onLogoFetched(err, data) {
    if (!err) {
      setLogo(data.url);
      setType(data.type);
    }
  }

  function addFile(fileInfo) {
    setFiles([...files, fileInfo]);
    if (setFieldValue) setFieldValue('fileContent', [...files, fileInfo]);
  }

  function emptyFileList() {
    setFiles([]);
  }

  function toggleDialog(e) {
    e.stopPropagation();
    setOpenDialog((prevValue) => !prevValue);
  }

  function getFileContent() {
    if (files.length && files[0].type !== 'text/xml') {
      return {
        value: files[0].base64,
        type: files[0].type,
      };
    }

    if (logo) {
      return { value: logo, type };
    }

    if (fileUrl) {
      return { value: fileUrl, type };
    }

    return { value: null, type: null };
  }

  const dispatch = useThunkDispatch();

  const fetchCompanyLogoCb = useCallback(
    () => dispatch(fetchCompanyLogo(filename, onLogoFetched)),
    [dispatch, filename]
  );

  useEffect(() => {
    if (filename) {
      if (fetchCompanyLogo) fetchCompanyLogoCb();
    } else {
      setLogo(null);
      if (values?.fileContent && values?.fileContent.length) {
        setFiles(values.fileContent);
        return;
      }
      setFiles([]);
    }
  }, [filename]);

  useEffect(() => {
    if (fileContent && !values?.cached && cacheId) {
      if (cacheId && fileContent.length) {
        addFile(fileContent[0]);
        setFieldValue('cached', true);
      }
    }
  }, [fileContent]);

  useEffect(() => {
    if (filename) {
      if (fetchCompanyLogo) fetchCompanyLogoCb();
    }
  }, []);

  useEffect(() => {
    setType(fileType);
  }, [fileType]);

  const onDrop = useCallback((acceptedFiles) => {
    emptyFileList();
    acceptedFiles.forEach((file) => {
      if (accept.includes(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const { name: fName, type: fType, size } = file;
          const base64 = reader.result;

          const fileInfo = {
            name: fName,
            type: fType,
            size: `${Math.round(size / 1024)} kB`,
            base64,
            file,
          };

          addFile(fileInfo);
          onFileSelected(file);
        };
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const renderOCRButton = () => {
    return (
      withOcr &&
      (!!files.length || extractFilename || canScan) && (
        <>
          <AppButton
            isDisabled={readOnly || disabled}
            color="secondaryLight"
            text={ocrText}
            onClick={onOcr}
          />
          <ErrorMessage showError={!!ocrError} error={'Erreur lors du scan'} />
        </>
      )
    );
  };
  const renderContentExpenseOrPurChase = () => {
    return (
      <>
        {label ? (
          <Typography style={{ marginBottom: '10px' }}>
            {label + (required ? '*' : '')}
          </Typography>
        ) : (
          ''
        )}
        {!(readOnly || disabled) && (
          <div className={classes.actionContainer}>
            <div {...getRootProps()} className={classes.btnDropzone}>
              <input {...getInputProps()} accept={accept} multiple={multiple} />
              {!extractFilename && (
                <AppButton
                  isDisabled={readOnly || disabled}
                  color="secondaryLight"
                  text={buttonText}
                />
              )}
            </div>
            {renderOCRButton()}
          </div>
        )}

        <div {...getRootProps()}>
          <input {...getInputProps()} accept={accept} multiple={multiple} />
          <FilePreview
            content={getFileContent()}
            toggleDialog={toggleDialog}
            shape={shape}
            customStyles={customStyles}
            isPreviewFile={isPreviewFile}
            disabled={disabled}
          />
        </div>
      </>
    );
  };

  function renderContent() {
    return (
      <div
        className={classes.wrapper}
        style={
          shape !== 'logoImage' &&
          shape !== 'kbizImage' &&
          shape !== 'profileLogo' &&
          shape !== 'sirenDoc'
            ? { marginTop: '115px' }
            : null
        }
      >
        {label ? <Typography>{label + (required ? '*' : '')}</Typography> : ''}
        <FilePreview
          content={getFileContent()}
          toggleDialog={toggleDialog}
          shape={shape}
          customStyles={customStyles}
        />

        {/* <AppDividerXS /> */}

        {/* Disable Upload button if extractFilename is exsited */}
        {!extractFilename && (
          <AppButton
            isDisabled={readOnly}
            color="transparentGrey"
            text={buttonText}
            noBorder
            fullWidth
            style={{ marginBottom: '10px' }}
            // className={readOnly ? classes.hideBtn : null}
          />
        )}

        {(!!files.length || extractFilename || fileUrl) && (
          <AppButton
            color="secondaryLight"
            text={t('open_file_in_new_window')}
            noBorder
            onClick={(event) => previewPDFfileInNewWindow(event)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
        )}
      </div>
    );
  }

  const previewPDFfileInNewWindow = (event) => {
    event.stopPropagation();

    const fileContent = getFileContent();

    let pdfWindow = window.open('', '_blank', 'width=1200,height=1200');

    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src="${fileContent.value}"></iframe>`
    );
  };

  return (
    <Fragment>
      {printErrors(name, false, errors, touched, classes.errorMessage)}
      <FilePreviewDialog
        content={getFileContent()}
        toggleDialog={toggleDialog}
        show={openDialog}
        shape={shape}
        customStyles={customStyles}
      />
      {isPreviewFile ? (
        renderContentExpenseOrPurChase()
      ) : (
        <>
          <div
            {...getRootProps()}
            className={classNames(
              classes.dropzone,
              isDragActive && classes.activeDropzone
            )}
          >
            {readOnly ? (
              ''
            ) : (
              <input {...getInputProps()} accept={accept} multiple={multiple} />
            )}

            {renderContent()}
          </div>
        </>
      )}
    </Fragment>
  );
};

UploadFile.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  filename: PropTypes.string,
  onFileSelected: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  fetchCompanyLogo: PropTypes.func,
  fileUrl: PropTypes.string,
  withOcr: PropTypes.bool,
  onOcr: PropTypes.func,
  ocrText: PropTypes.string,
  ocrError: PropTypes.object,
  name: PropTypes.string,
  mandatory: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  readOnly: PropTypes.bool,
  shape: PropTypes.string,
  customStyles: PropTypes.object,
  isPreviewFile: PropTypes.bool,
  disabled: PropTypes.bool,
};

UploadFile.defaultProps = {
  multiple: false,
  accept: '*/*',
};

export default UploadFile;
